module.exports = {
  sizes: {
    b_desktop: 1199,
    b_desktop_s: 991,
    b_tablet: 767,
    mobile: 425,
  },
  blue: "#0077FF",
  white: "#FFF",
  black: "#000",
  logoColor: "#262626",
  darkwhite: "#F8F8F8",
  grayBorder: "#EBEBEB",
  darkgray: "#323232",
  whitegray: "#D1D1D1",
  almostWhite: "#E1E1E1",
  bluegray: "#5E6977",
  facebook: "#4267b2",
  google: "#db3236",
  kakao: "#f7e317",
  kakaoFont: "#3c1e1e",
  naver: "#1ec800",
}
